<template>
  <div class="password-input">
    <input
      v-if="inVisible"
      required
      type="password"
      :value="value"
      :placeholder="placeholder"
      @input="$emit('input', $event.target.value)"
    />
    <input
      v-else
      required
      type="text"
      :placeholder="placeholder"
      :value="value"
      @input="$emit('input', $event.target.value)"
    />
    <div @click="toggleVisibility">
      <font-awesome-icon
        :icon="inVisible ? 'fa-regular fa-eye' : 'fa-regular fa-eye-slash'"
        style="color: #bfbfbf"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
    },
    value: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      inVisible: true,
    };
  },
  methods: {
    toggleVisibility() {
      this.inVisible = !this.inVisible;
    },
  },
};
</script>

<style scoped lang="scss">
.password-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  input {
    background: white;
    outline: none;
    border-radius: 0.3rem 0 0 0.3rem;
    padding-left: 0.8rem;
    height: 3rem;
    font-size: 0.8rem;
    width: 100%;
  }
  div {
    width: 3rem;
    height: 3rem;
    background: white;
    border-radius: 0 0.3rem 0.3rem 0;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
