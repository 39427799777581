import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { createHead } from '@vueuse/head';
import './assets/styles/main.scss'
import { createMetaManager } from 'vue-meta';

//  ======  PLUGINS  ====
import axiosInstance from './plugins/axios'
import Moment from './plugins/moment.js'
import Vue3Autocounter from 'vue3-autocounter';
import VueSocialSharing from 'vue-social-sharing'
import mobileMixin from './utils/mobileMixin';
import i18n from './plugins/i18n'
import { loadFonts } from './plugins/webfontloader'
import ToastPlugin from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-bootstrap.css';
import 'maz-ui/styles'

//  ======  Font and Icon libraries  ===
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { icons } from './plugins/icons'
library.add(Object.values(icons))
loadFonts()


//   ===========    COMPONENTS   ============
import InDevelopment from '@/components/Layout/InDevelopment.vue'
import LoginForm from '@/components/Layout/LoginForm.vue';
import Loading from '@/components/Layout/Loading.vue'
import Sidebar from '@/components/Layout/Sidebar.vue'
import PasswordInput from '@/components/Elements/PasswordInput.vue'
const head = createHead();
const metaManager = createMetaManager();

createApp(App)
    .component('font-awesome-icon', FontAwesomeIcon)
    .component('vue3-autocounter', Vue3Autocounter)
    .component('InDevelopment', InDevelopment)
    .component('LoginForm', LoginForm)
    .component('Loading', Loading)
    .component('Sidebar', Sidebar)
    .component('PasswordInput', PasswordInput)
    .mixin(mobileMixin)
    .provide('axios', axiosInstance)
    .use(VueSocialSharing)
    .use(metaManager)
    .use(head)
    .use(i18n)
    .use(router)
    .use(Moment)
    .use(store)
    .use(ToastPlugin)
    .use(vuetify)
    .mount('#app')