<template>
  <v-dialog v-model="dialogs.dialog">
    <div class="login">
      <font-awesome-icon
        @click="closeForm()"
        class="close"
        icon="fa-solid fa-circle-xmark"
        style="color: gray"
      />
      <!-- Login -->
      <div class="login__left" v-if="form === 'login'">
        <h1>{{ $t("login.enterAccount") }}</h1>
        <p>
          {{ $t("login.noAccount") }}
          <span @click="changeForm('register')"
            >{{ $t("login.letCreateAccount") }} {{ $t("login.itWillTakeMinutes") }}</span
          >
        </p>
        <form @submit.prevent="logIn" class="login__left-form">
          <div>
            <label for="login">{{ $t("login.username") }}</label>
            <input
              required
              v-model="login.login"
              type="text"
              :placeholder="$t('login.enterUsername')"
            />
          </div>
          <div>
            <label for="login">{{ $t("login.password") }}</label>
            <div class="password-input">
              <input
                v-if="inVisible"
                required
                type="password"
                v-model="login.password"
                :placeholder="$t('login.enterPassword')"
              />
              <input v-else required type="text" v-model="login.password" />
              <div @click="toggleVisibility">
                <font-awesome-icon
                  :icon="inVisible ? 'fa-regular fa-eye' : 'fa-regular fa-eye-slash'"
                  style="color: #bfbfbf"
                />
              </div>
            </div>
          </div>
          <button type="submit">{{ $t("login.login") }}</button>
        </form>
        <div class="line"></div>
        <a @click="oAuth()" class="login__left-oauth">
          <font-awesome-icon icon="fa-brands fa-google" size="xl" />
          {{ $t("login.loginWithGoogle") }}
        </a>
      </div>

      <div class="login__verify" v-if="form === 'verify'">
        <form @submit.prevent="confirmEmail">
          <h1>{{ $t("login.confirmEmail") }}</h1>
          <p>
            {{ $t("login.verifyEmail") }} <span>{{ register.email }}</span>
          </p>
          <input required v-model="confirmPin" type="text" maxlength="6" />
          <button type="submit">{{ $t("login.continue") }}</button>
        </form>
      </div>
      <Loading v-if="loading" />

      <!-- Register -->
      <div class="login__left" v-if="form === 'register'">
        <h1>{{ $t("login.createAccount") }}</h1>
        <p>
          {{ $t("login.haveAccount") }}
          <span @click="changeForm('login')">{{ $t("login.login") }}</span>
        </p>
        <form @submit.prevent="signIn" class="login__left-form">
          <div>
            <label for="login">{{ $t("login.username") }}</label>
            <input
              required
              v-model="register.login"
              type="text"
              :placeholder="$t('login.enterUsername')"
            />
          </div>
          <div>
            <label for="login">{{ $t("login.email") }}</label>
            <input
              required
              v-model="register.email"
              type="email"
              :placeholder="$t('login.enterEmail')"
            />
          </div>
          <div>
            <label for="login">{{ $t("login.password") }}</label>
            <div class="password-input">
              <input
                v-if="inVisible"
                required
                type="password"
                v-model="register.password"
                :placeholder="$t('login.enterPassword')"
              />
              <input v-else required type="text" v-model="login.password" />
              <div @click="toggleVisibility">
                <font-awesome-icon
                  :icon="inVisible ? 'fa-regular fa-eye' : 'fa-regular fa-eye-slash'"
                  style="color: #bfbfbf"
                />
              </div>
            </div>
          </div>
          <button type="submit">{{ $t("login.register") }}</button>
        </form>
        <div class="line"></div>
        <a @click="oAuth()" class="login__left-oauth">
          <font-awesome-icon icon="fa-brands fa-google" size="xl" />
          {{ $t("login.loginWithGoogle") }}
        </a>
      </div>
      <div class="login__right"></div>
    </div>
  </v-dialog>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  props: ["dialogs"],
  inject: ["axios"],
  data() {
    return {
      dialog: true,
      loading: false,
      form: "login",
      confirmPin: null,
      oauthEmail: null,
      showPassword: false,
      inVisible: true,
      register: {
        firstName: null,
        lastName: null,
        login: null,
        email: null,
        password: null,
      },
      login: {
        login: "",
        password: "",
      },
    };
  },
  methods: {
    ...mapMutations(["changeKeyValue"]),
    toggleVisibility() {
      this.inVisible = !this.inVisible;
    },
    closeForm() {
      this.form = "login";
      this.dialogs.dialog = false;
    },
    changeForm(form) {
      this.form = form;
    },
    oAuth() {
      window.location.href = "https://medicalka.ru/medical/oauth2/authorization/google";
    },
    logIn() {
      this.loading = true;
      this.axios
        .post("auth/v1/logIn", this.login)
        .then((response) => {
          const token = response?.data?.accessToken;
          localStorage.setItem("token", token);
          this.loading = false;
          this.dialogs.dialog = false;
          this.changeKeyValue();
        })
        .catch((error) => {
          this.loading = false;
          this.$toast.open({
            message: error?.response?.data,
            type: "error",
            position: "top-right",
          });
        });
    },
    confirmEmail() {
      this.loading = true;
      this.axios
        .post("auth/v1/confirmEmail", {
          email: this.register.email,
          pin: this.confirmPin,
        })
        .then((response) => {
          this.$toast.open({
            message: "Email успешно подтвержден",
            type: "success",
            position: "top-right",
          });
          this.form = "login";
          this.loading = false;
        })
        .catch((error) => {
          this.$toast.open({
            message: error?.response?.data,
            type: "error",
            position: "top-right",
          });
        });
    },
    signIn() {
      this.loading = true;
      this.axios
        .post("auth/v1/signIn", this.register)
        .then((response) => {
          this.$toast.open({
            message: "Вы успешно зарегистрировались",
            type: "success",
            position: "top-right",
          });
          this.loading = false;
          this.form = "verify";
        })
        .catch((error) => {
          if (error?.response?.status === 500) {
            this.$toast.open({
              message: "Пользователь с этим адресом электронной почты уже существует",
              type: "error",
              position: "top-right",
            });
          }
          this.loading = false;
        });
      this.loading = false;
    },
    refreshToken(token) {
      this.axios
        .get(`auth/v1/getJwtByOAuthToken?token=${token}`, this.register)
        .then((response) => {
          // const token = response?.data;
          // localStorage.setItem("token", token);
          // this.$toast.open({
          //   message: "Вы успешно вошли в аккаунт",
          //   type: "success",
          //   position: "top-right",
          // });
          // window.location.href = "/";
        })
        .catch((error) => {
          this.$toast.open({
            message: error?.response?.data,
            type: "error",
            position: "top-right",
          });
        });
    },
    getOauthInfo() {
      const token = this.$route.params.token;
      this.axios
        .get(`auth/v1/getJwtByOAuthToken/${token}`)
        .then((response) => {
          localStorage.setItem("token", response.data.accessToken);
          this.$router.push("/");
        })
        .catch((error) => {});
    },
  },
  mounted() {
    if (window.location.search.includes("token")) {
      this.refreshToken(window.location.href);
      this.$router.push("oauth-register/" + window.location.search);
    }
  },
};
</script>

<style lang="scss">
.password-input {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  input {
    background: white;
    outline: none;
    border-radius: 0.3rem 0 0 0.3rem;
    padding-left: 0.8rem;
    height: 3rem;
    font-size: 0.8rem;
    width: 100%;
  }
  div {
    width: 3rem;
    height: 3rem;
    background: white;
    border-radius: 0 0.3rem 0.3rem 0;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
