<template>
  <v-app>
    <Navbar :key="keyValue" />
    <Loading v-if="isLoading" />
    <router-view :key="$route.fullPath" class="container" v-else />
    <Sidebar v-if="mobile" />
    <Footer :key="keyValue" />
  </v-app>
</template>

<script>
import Navbar from "@/components/Layout/Navbar.vue";
import Footer from "@/components/Layout/Footer.vue";
import { fetchPosts } from "./services/postService";
import { mapGetters } from "vuex";
export default {
  name: "App",
  data() {
    return {
      token: localStorage.getItem("token"),
    };
  },
  components: {
    Navbar,
    Footer,
  },
  computed: {
    ...mapGetters(["isLoading", "keyValue"]),
  },
  methods: {
    async generateSitemap() {
      try {
        const posts = await fetchPosts();

        let sitemapXml = '<?xml version="1.0" encoding="UTF-8"?>';
        sitemapXml +=
          '<urlset xmlns="https://medicalka.com/posts/posts/уход-кожей/уход-за-кожей">';

        posts.forEach((post) => {
          const postUrl = `${window.location.origin}/topic/${encodeURIComponent(
            post.title
          )}`;
          sitemapXml += `
            <url>
              <loc>${postUrl}</loc>
              <changefreq>weekly</changefreq>
              <priority>0.8</priority>
            </url>
          `;
        });

        sitemapXml += "</urlset>";

        const blob = new Blob([sitemapXml], { type: "text/xml" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "sitemap.xml";
        link.click();

        console.log("Sitemap generated successfully.");
      } catch (error) {
        console.error("Error generating sitemap:", error);
      }
    },
  },
  mounted() {
    // this.generateSitemap();
  },
};
</script>
