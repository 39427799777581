import axios from 'axios';
const token = localStorage.getItem('token')
if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
}
if (token) {
    try {
        // Correct the token format for Base64 URL encoding
        let base64Url = token.split('.')[1];
        base64Url = base64Url.replace(/-/g, '+').replace(/_/g, '/');

        // Add padding if necessary
        switch (base64Url.length % 4) {
            case 2:
                base64Url += '==';
                break;
            case 3:
                base64Url += '=';
                break;
        }

        const tokenData = JSON.parse(atob(base64Url));
        const tokenExpiration = tokenData.exp * 1000;
        const currentTimestamp = new Date().getTime();

        if (tokenExpiration < currentTimestamp) {
            localStorage.removeItem('token');
        }
    } catch (error) {
        console.error('Error parsing token:', error);
        localStorage.removeItem('token'); // Optionally remove the token if parsing fails
    }
}
const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL
})

axiosInstance.interceptors.response.use(
    (response) => response,
    async(error) => {
        const { config, response: { status } = {} } = error;
        const originalRequest = config;

        if (status === 401) {
            localStorage.removeItem('token');

            try {
                const authResponse = await axios.post('/auth', {});

                const newToken = authResponse.data.token;
                localStorage.setItem('token', newToken);

                originalRequest.headers.Authorization = `Bearer ${newToken}`;
                return axios(originalRequest);
            } catch (error) {
                console.error('Authentication error:', error);
            }
        }

        return Promise.reject(error);
    }
);

export default axiosInstance