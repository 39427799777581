export default {
    home: {
        welcome: '<span>Medicalka</span> GA XUSH KELIBSIZ',
        welcomeText: "Bizning veb-saytimiz turli kasalliklar haqida ishonchli va dolzarb ma'lumotlarni taqdim etishga bag'ishlangan.",
        mainThemes: 'Asosiy mavzular',
        seeAll: "HAMMASINI KO'RISH",
        moreVideos: "KO'PROQ TOP VIDEOLAR",
        mostPopular: 'Eng ommabop maqolalar',
        mostNew: 'Eng yangi maqolalar',
        footerSubTitle: "Sog'likni kuchaytirish: bilim shifo bo'ladigan joyda.",
        aboutCompanyTitle: 'Ilm va halollik biz uchun muhim',
        aboutCompanySubtitle: "Medicalka kompaniyasida biz baham ko'rgan narsalarda sifat, tadqiqot va shaffoflik bo'yicha yuqori standartlarni o'rnatamiz, bu sizga faqat eng yaxshilaridan foydalanish imkoniyatini ta'minlaydi. Mana shunday:",
        aboutCompanyFirstStage: "Aniqligini ta'minlash uchun 20 000 dan ortiq maqolamizning har biri shifokor, hamshira yoki terapevt kabi tibbiy ekspert tomonidan ko'rib chiqiladi.",
        aboutCompanySecondStage: "Bizning tavsiyalarimiz qat'iy sog'liqni saqlash va tibbiy monitoring standartlarimiz tufayli joriy va tadqiqotga asoslangan.",
        aboutCompanyThirdStage: "Tanlangan brendlar tibbiy aniqligi, biznes amaliyoti va ijtimoiy taʼsiri uchun tekshiriladi.",
        aboutCompanyRead: "Jarayonlar haqida batafsil o'qish",
        ourHeroes: "Bizning qahramonlar",
        healthTopics: "Salomatlik haqida mavzular",
        useful: "Foydali resurslar"
    },
    login: {
        enterAccount: "Profilga kirish",
        noAccount: "Akkaunt yo'qmi?",
        username: "Foydalanuvchi nomi",
        enterFirstname: "Ismingizni kiriting...",
        enterLastname: "Familiyangizni kiriting...",
        enterUsername: "Foydalanuvchi nomini kiriting...",
        enterPassword: "Parolni kiriting...",
        enterPhoneNumber: "Telefon raqamini kiriting",
        enterEmail: "Emailni kiriting...",
        letCreateAccount: "Ro'yhatdan o'tish,",
        itWillTakeMinutes: "bu kamida bir daqiqa davom etadi",
        login: "Kirish",
        loginWithGoogle: "Google bilan kirish",
        confirmEmail: "Emailni tasdiqlang",
        continue: "Davom etish",
        createAccount: "Ro'yhatdan o'tish",
        haveAccount: "Akkauntingiz bormi?",
        lastName: "Familiya",
        phoneNumber: "Telefon raqami",
        firstName: "Ism",
        email: "Elektron po'chta",
        password: "Parol",
        register: "Ro'yxatdan o'ting",
        verifyEmail: "Iltimos, elektron po'chta manzilingizga jo'natilgan 6-raqamli tasdiq kodi ni kiriting.",
        bio: "O'zingiz haqingizda malumot"
    },

    faq: {
        questions: "Ko'p so'raladigan savollar",
    },
    post: {
        title: "Nomi",
        shortTitle: "Qisqa malumot",
        description: "To'liq malumot",
        hashtags: "Heshteglar",
        add: "Qo'shish",
        imgLink: "Rasm havolasi",
        topics: "Mavzular",
        save: "Saqlash",
        relatedPosts: "O'xshash maqolalar"
    },
    about: {
        our__company: "Biz haqimizda",
        about__us: "Medicalka.com — bu sog'liqqa va yaxshi holatga bag'ishlangan axborot sayti. Sayt hayot sifatini yaxshilash va sog'liqni boshqarishga qaratilgan chuqurlashtirilgan maqolalarni va ekspert maslahatlarni taklif qiladi. U o'quvchilarga sog'liq va yaxshi holatlariga oid ma'lumotlarni ko'rsatib beradi va sog'liq haqida ma'lumotlarga ishonch bilan qaror qabul qilishlariga yordam beradi. Medicalka.com biliming yuqori darajada bo'lgan va sog'liq bilan ta'minlash uchun xizmat qiluvchi axborot tuzilmasidir.",
        process: "Bizning jarayonimiz",
        support: "Ishonchni ta'minlash",
        standart: "Jurnalistik standartlar",
        prioritet: "Tasdiqlash uchun ustuvorlik",
        monitoring: "Doimiy monitoring",
        prioritetDesc: "Sogʻliq haqida ma'lumotni oson topish mumkin, lekin u noaniq, achib-achib, noaniq, noqulay yoki erkin emas bo'lishi mumkin. Biz bu taraqqiyotimizni o'zgartirish uchun bu yerda tayyor bo'lamiz. Biz ishonchli, ma'lumotlarga asoslangan, aniq, tushunarli, erkin, birgalikda mumkin bo'ladigan, tashvish va amaliy yordam beradigan tarkibiylikni taqdim etamiz. Bizning ma'lumotlarimiz boyicha biz umumiy yaxobilikni ta'minlash va sog'liq va hayot uskunalari o'rtasidagi muhim aloqani qurishda yordam beramiz. Biz uni 'barcha insonga bo 'lgan sog'liq' deb nomlaymiz. Bu xulosaga muvofiq, biz keng doira bo'yicha mavzular va fikrlarni ochiq va ob'ektiv ravishda taqdim etamiz. Biz har bir kishining yo'li farqli bo'lishini tushunamiz, shuning uchun ham inklyuzivlik va tashvish uchun o'zgarishni ta'minlaymiz. Biz bu yerda – osudan bir inchik – sogʻligini yaxshilashni xohlavergan barcha insonlar uchun.",
        supportDesc: "Sog'liq haqida ma'lumotni oson topish mumkin, lekin u eshiklar yoki tanqidlovchi, noaniqlik, relevanssizlik yoki yetarli emas. Biz bu holatni o'zgartirish uchun bu erda bo'lamiz. Biz, ishonilgan, aniqligi, faktalar asosida, halqalar uchun osonlik bilan tushuniladigan va amaliy maqsadlarga muvofiq bo'lgan tarkibni taklif etamiz. Bizning ma'lumotlarning barcha tarkibida, umuman yaxshig'a qarab, sog'lik bilan hayot shakli oralig'ida muhim bog'lanishni o'rnatishga intilamiz. Biz bunaqa 'shaxsning barcha sog'lig'i' deylik. U ni tasdiqlash uchun, bizning nuqsonliliksiz va ob'ektiv ravishda davolanuvchi ko'p yo'nalishlar va nazariyalar haqida ma'lumot berishni qo'llaymiz. Biz har bir odamning yo'lining boshqa bo'lishini tushunamiz, shuning uchun inklyuzivlik va intilamizni ta'kidlaymiz. Biz, sog'ligini yaxshilashni istagan hammasi, osod bo'lgan, birga, ayblashmaysiz bu erdamiz.",
        standartDesc: "Siz sog'liq haqingizda aniq ma'lumot olish uchun Medicalka kelgansiz, shuning uchun bizga yuqori jurnalistik standartlarni belgilash majburiyatimiz bor. Bizning yangiliklar va o'qitish materiallarimiz to'g'ri, bemalol, vaqtini sungat, samarali, ilmiy asoslangan, ishonchli va hamjamiyatli bo'lishi kerak. Bizning mavzu bo'yicha materiallarimiz hamu jihatdan empatiyali, ochiqlik va hurmat bilan o'zgina fikrlarni ko'rsatadi. Biz bu standartlarga muvofiqligini qanday ta'minlaymiz: Biz ishtirokchilarni tanlashda ehtiyotkorlik bilan munosabat qilamiz va ularni mamnun qilishda doimiy tayyorlaymiz. Tahririy jamoamiz tajribali yozuvchilarni darommad topib olish bilan e'tiborli ravishda tanlaydi. Kontent yaratuvchilarimizning kasbiy bilimlarini va maskanbop tajribalarini tekshiramiz. Ular asosiy tadqiqot va eng yaxshi amalga oshirishni topishda o'rgatiladi, va doimiy takrorlash va o'rganish ta'minlanadi. Bizning tibbiy ma'lumotlarimizni tibbiy mutaxassislar tibbiy aniqligini tekshiradilar. Medicalka Media tibbiy tarmog'i, ta'momiyligi va kengaygan tajribaga ega tibbiy vakillardan iborat. Turkumlarimizning turli tibbiy mutaxassisliklarda bilimlaridan tashqari, tibbiy sharqda tajribalar, tadqiqotlar va foydalanuvchilarining huquqlarini himoya qilish bilan bog'liq o'z bilimlarini ham o'z ichiga qo'yadilar. Biz hamkorlik qiladigan barcha brendlarimizni ehtiyotkorlik bilan tekshiramiz va ularni kontentimizda aytiladi. Brend va kontentga taminotini ta'minlash jamoamiz brendning boshqaruv biznes amaliyotini o'rganadi va aholi sog'lik sohasidagi ma'lumotlari xaqida mavjud ilmiy ma'lumotlarga asoslangan tarzda baholaydi.",
        monitoringDesc: "Sog'liq haqida ma'lumot oson topish mumkin, lekin bu ma'lumotlar qiyin, qafqaznoma, noaniq, aloqador yoki erishilmaydi bo'lishi mumkin. Biz bu holatni o'zgartirish uchun bu yerda bo'lamiz. Biz ishonadigan, ma'lumotlarga asoslangan, aniq, tushunarli, qisqa, bir qatorda chaqiruvchi va amaliyot sifatida maslaklanadigan kontent beramiz. Bizning kontent orqali, umumiy tinchlik uchun chiroyli bog' va sog'liqning o'rtasidagi muhim bog'lanishni o'rnatishingizga yordam beramiz. Biz buni 'butun odam sog'ligi' deb ataymiz.Ushbu ideani tasdiqlash maqsadida,biz keng qamrovli mavzularni va fikrlarni ochiq va obyektiv ravishda tanqidiy qilamiz.Biz har bir insonning yo 'li farqlanadi deb tushunamiz, shuning uchun biz inkluzivlik va nefrat aytmaslikni ta' kidlashga intilamiz.Biz bu erda - nanaroqlanmasdan - sog 'lomingizni yaxshilashni istagan har bir kishi uchun."
    },
    question: {
        askQuestion: "Savol yo'llash",
        description: "Istalgan vaqtda biz bilan bog'lanishdan tortinmang. Imkon qadar tezroq siz bilan bog'lanamiz",
        yourName: "Sizning ismingiz",
        yourEmail: "Sizning elektron po'chtangiz",
        yourMessage: "Xabaringizni yozing",
        sendMessage: "Xabar yuborish",
        enterName: 'Ismingizni kiriting',
        enterEmail: "Elektron po'chta manzilingizni kiriting ",
        enterMessage: 'Savolingizni bu yerda yozing...'
    },
    calculator: {
        menstruationCalc: "Hayz ko'rish kalkulyatori",
        calculateMenstruation: "Hayz kunlarini hisoblash",
        enterFirstDay: "So'nggi hayz ko'rgan kuni bilan boshlang'ich kuni kiriting",
        enterContinuesCycle: "Hayz davomiyligini kiriting",
        countCycles: "Qancha sikllar hisoblash kerak",
        calorieCalc: "Kaloriya kalkulyatori",
        pregnancyCalc: "Homiladorlik kalkulyatori",
        calculatePregnancy: "Homiladorlik muddatini hisoblash",
        waterCalc: "Suv-balans kalkulyatori",
        waterCalcDesc: "Yengil hayot usuli uchun asosiy tamoyillardan biri organizmning suv-tuz balansini saqlash zarurati bo'lib, qisqa aytganda, odam organizmi kun davomida yo'qotgan suv miqdori qadarini qayta tiklashga ega.",
        calculateWater: "Kuniga kerakli suv ehtiyojini hisoblash",
        activeExercises: "Aktiv jismoniy harakatlar (soatda)",
        waterInLitr: "Kuniga suv miqdori",
        enterActiveExercises: "Aktiv harakatni kiriting",
        firstMenstruation: "Oxirgi hayz ko'rish kunini kiriting",
        beneficalActivities: "Homiladorlik davrida foydali mashg'ulotlar",
        walking: "Yurish",
        cycling: "Velosipedda sayr",
        yoga: "Yo'ga",
        pregnancyDesc: "Odatda ayol bola zaiflanishi sanasini aniqlay olmaydi (bu osonlik bilan xato qilinadi). Lekin u oxirgi hayz ko'rish kuni to'g'ri biladi - bu shunday bo'lsa, ushbu sana asosida tuyg'uq tashkil etish muddati, tahminiy foetning vaqtinching ko'rsatkichlari va hattoki maydalashgan puxta hajmi ham hisoblanishi mumkin.",
        bornDate: "Tahminiy tug'ilish sanasi:",
        indexMassCalc: "Tana massasi indeksi (BMI) kalkulyatori",
        calculateCalorie: "Kaloriyani hisoblash",
        massIndex: "BMI",
        calculateIndexMass: "Tana massasi indeksini hisoblash",
        indexMassDesc: "Tana massasi indeksi hisoblash, og'riq va bo'yi o'laroq va ortoqcha nisbatda qayerda joylashganligini aniqlaydi, kalkulyator erkak va ayollar uchun mo'ljallangan.",
        clear: "Tozalash",
        calculate: "Hisoblash",
        result: "Hisobot natijalari:",
        enterAge: "Yoshingizni kiriting...",
        enterWeight: "Vazningizni kiriting...",
        enterHeight: "Bo'yingizni kiriting...",
        yourAge: "Sizning yoshingiz",
        yourWeight: "Sizning og'irlingiz (kg)",
        yourGender: "Sizning jinsingiz",
        yourHeight: "Sizning bo'yingiz (sm)",
        protein: "Oqsillar",
        carbs: "Uglevodlar",
        fats: "Yog'lar",
        calories: "Kaloriyalar",
        grammsPerDay: "gram (kuniga)",
        kkalPerDay: "kkal (kuniga)",
        male: "Erkak",
        female: "Ayol"
    },
    navbar: {
        registration: "Kirish",
        logOut: "Chiqish",
        profileSettings: "Profil sozlamalari",
        publications: "Mening postlarim",
        likedPosts: "Yoqtirgan postlarim",
        comments: 'Izohlar'
    },
    profile: {
        title: "Profil",
        createPost: "Yangi post yozish",
        yourAccountNotActive: "Sizning profilingiz tasdiqlanmagan",
        notActiveProfile: "(profil tasdiqlanmagan)",
        saveChanges: "O'zgartirishlarni saqlash",
        noPublications: "Kechirasiz, bu erda hali hech bir nashr yo'q",
        watch: "Ko'rish",
        noComments: "Foydalanuvchi hali hech bir sharh qo'yamagan",
        confirm: "Tasdiqlash",
        downloadFile: "Faylni yuklash",
        waitingModeration: "Moderatsiya kutilmoqda "
    },

    videos: {
        usefulVideos: "Sizning sog'likingiz uchun eng foydali videolar",
        usefulVideosDesc: "Sarlavha mavzusi uchun ba'zi tavsif matni, kelajakdagi maqsadlar uchun tavsif bo'ladi",
        lastVideos: "So'nggi videolar"
    },
    footer: {
        disclaimer: "Diskleymer",
        privacy: "Maxfiylik siyosati",
        ads_politic: "Reklama siyosati",
        for_ads: "Reklama berish uchun"
    },
    ads: {
        fillForm: "Fo'rmani to'ldiring va biz siz bilan bog'lanamiz",
        fio: "FIO*",
        companyTitle: "Kompaniya nomi*",
        email: "Elektron pochta*",
        location: "Kompaniya joylashuvi*",
        message: "Xabar",
        send: "Yuborish"
    },
    massIndex: {
        bodyIndex: "Tana massasi indeksi",
        massPerson: "Insonning og'irlik va bo'yi o'rtasidagi moslik",
        lessMass: "Tana massasi kamaytirilgan",
        fewMass: "Tana massasi yetarlicha kamaytirilgan",
        norm: "Norma",
        preFat: "Tana massasi ortishi (oldindan og'ir)",
        firstStepFat: "Birinchi bosqich o'g'irlik",
        secondStepFat: "Ikkinchi bosqich o'g'irlik",
        thirdStepFat: "Uchinchi bosqich o'g'irlik (morbidiy)",
        andLess: "va kamroq",
        andMore: "va ortiq",
        lackOfWeight: "Vazn yetishmasligi",
        healthy: "Sog'lom",
        overweight: "Ortiqcha vazn",
        obesity: "Semizlik",
        extremelyObese: "Juda semizlik",
    },
    enterSixDigit: "Olti xonali kodni kiriting",
    smsConfirm: "Sms tasdiqlash",
    sendSms: "Telefon raqamingizga olti xonali tasdiqlash kodi bo'lgan matnli xabar yuborildi.",
    inDevelopment: "Bo'lim ishlab chiqilmoqda...",
    search: "Qidirish",
    notPublications: "Afsuski, nashr topilmadi",
    leaveNumber: "Telefon raqamingizni qoldiring va bonus oling",
    send: "Yuborish",
    days: "kun",
    inLitrs: "litrda",
    noComments: "Bu yerda hali hech qanday izoh yo'q, siz birinchi bo'lishingiz mumkin!"

}