<template>
  <div class="development">
    <v-img class="development__image" src="@/assets/images/wait.svg" ></v-img>
    <h1>{{ $t('inDevelopment') }}</h1>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped lang="scss">
.development{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4rem;
  &__image{
    height: 50%;
    width: 50%;
    margin: 0 auto;
  }
}
</style>