import { createStore } from 'vuex'

export default createStore({
    state: {
        loading: false,
        profileTab: "profile",
        key: 1,
        sidebarStatus: false
    },
    getters: {
        keyValue(state) {
            return state.key
        },
        isLoading(state) {
            return state.loading
        },
        profileTabVal(state) {
            return state.profileTab
        },
        getSidebarState(state) {
            return state.sidebarStatus
        },
    },
    mutations: {
        changeKeyValue(state) {
            window.location.reload()
        },
        setLoading(state, value) {
            state.loading = value
        },
        setProfileTab(state, value) {
            state.profileTab = value
        },
        toggleSidebar(state, value) {
            state.sidebarStatus = value
        }
    },
    actions: {},
    modules: {}
})