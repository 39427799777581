<!-- LoadingComponent.vue -->
<template>
    <div class="overlay">
      <v-img src="@/assets/images/loading.svg" alt="Loading..." class="loading-gif"></v-img>
    </div>
  </template>
  
  
  <script>
import { mapGetters } from 'vuex';
  export default {
    name: 'LoadingComponent',
    computed: {
      ...mapGetters(['isLoading'])
    }
  };
  </script>
  <style scoped>
.overlay {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
  display: flex;
  background: #F5F5F5;
  justify-content: center;
  align-items: center;
}

.loading-gif {
  width: 45px; 
  height: 45px;
}
</style>
