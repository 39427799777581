import { createI18n } from 'vue-i18n';
import uz from '../locales/uz';
import ru from '../locales/ru';

const authorisedLanguages = ['ru', 'uz'];
const locale = authorisedLanguages.includes(navigator.language) ? navigator.language : 'ru';

const i18n = createI18n({
    seo: true,
    skipSettingLocaleOnNavigate: true,
    locale: 'ru',
    defaultLocale: 'ru',
    fallbackLocale: "ru",
    messages: { uz, ru },
});

export default i18n