import { createRouter, createWebHistory } from 'vue-router'
import i18n from '../plugins/i18n'

const routes = [{
        path: '/',
        redirect: () => {
            let userLanguage = [localStorage.getItem("language") || "ru"];
            if (userLanguage.includes('uz')) {
                return '/uz';
            } else {
                return '/ru';
            }
        },
    },
    {
        path: '/oauth-login/:token',
        name: 'oauth-login',
        component: () =>
            import ('../views/oAuth/AuthLogin.vue')
    },
    {
        path: '/:lang',
        component: {
            template: '<router-view></router-view>'
        },
        beforeEnter: (to, from, next) => {
            const lang = to.params.lang;
            if (['uz', 'ru'].includes(lang)) {
                next();
            } else {
                next('/uz');
            }
        },
        children: [{
                path: '',
                name: 'home',
                component: () =>
                    import ('../views/Home/index.vue')
            },
            {
                path: 'oauth-register/:token',
                name: 'oauth',
                component: () =>
                    import ('../views/oAuth/index.vue')
            },
            {
                path: 'create-post',
                name: 'create-post',
                component: () =>
                    import ('../views/Posts/PostCreate.vue')
            },
            {
                path: 'posts/:id',
                name: 'post-id',
                component: () =>
                    import ('../views/Posts/PostId.vue')
            },
            {
                path: 'heroes/:id',
                name: 'hero-id',
                component: () =>
                    import ('../views/Heroes/HeroId.vue')
            },
            {
                path: 'our-heroes',
                name: 'our-heroes',
                component: () =>
                    import ('../views/Heroes/index.vue'),
                meta: {
                    title: 'Medicalka | Наши герои',
                    description: 'Наши герои'
                }
            },
            {
                path: 'contact-information',
                name: 'contact-information',
                component: () =>
                    import ('../views/Contacts/index.vue')
            },
            {
                path: 'topics',
                name: 'topics',
                component: () =>
                    import ('../views/Topics/index.vue'),
                meta: {
                    title: 'Medicalka | Темы о здоровье',
                    metaTags: [{
                        name: 'description',
                        content: 'Самые полезные темы для вашего здоровья'
                    }]
                },
            },
            {
                path: 'topics/:id',
                name: 'topic-id',
                props: true,
                component: () =>
                    import ('../views/Topics/TopicId.vue')
            },
            {
                path: 'videos',
                name: 'videos',
                component: () =>
                    import ('../views/Videos/index.vue'),
                meta: {
                    title: 'Medicalka | Самые полезные видео для вашего здоровья',
                    description: 'Самые полезные видео для вашего здоровья'
                }
            },
            {
                path: 'calorie-calculator',
                name: 'calorie-calculator',
                component: () =>
                    import ('../views/Calculators/CalorieCalc.vue'),
                meta: {
                    title: 'Medicalka | Калькулятор калорий',
                    description: 'Калькулятор калорий позволит вам рассчитать то количество энергии, которое необходимо ежедневно получать вашему организму в зависимости от вашего роста, веса, возраста и степени физической активности (норма калорий).',
                    metaTags: [{
                        name: 'description',
                        content: 'Learn more about our company.'
                    }]
                }
            },
            {
                path: 'heart-calculator',
                name: 'heart-calculator',
                component: () =>
                    import ('../views/Calculators/HeardbeatCalc.vue')
            },
            {
                path: 'body-mass-index',
                name: 'body-mass-index',
                component: () =>
                    import ('../views/Calculators/MassCalc.vue'),
                meta: {
                    title: 'Medicalka | Калькулятор индекса массы тела (ИМТ)',
                    description: 'Калькулятор индекса массы тела (ИМТ) в зависимости от роста, веса и вашего пола. Дополнительные пояснения по полученным значениям ИМТ.'
                }
            },
            {
                path: 'water-balance',
                name: 'water-balance',
                component: () =>
                    import ('../views/Calculators/WaterCalc.vue'),
                meta: {
                    title: 'Medicalka | Калькулятор водного баланса',
                    description: 'Об этом калькуляторе. *Этот калькулятор позволяет рассчитать гидробаланс за сутки как с операционными и другими потерями, так и без них.'
                }
            },
            {
                path: 'pregnancy-calendar',
                name: 'pregnancy-calendar',
                component: () =>
                    import ('../views/Calculators/PregnancyCalc.vue'),
                meta: {
                    title: 'Medicalka | Калькулятор беременности',
                    description: 'Приведенные в таблице даты являются ориентировочными и не имеют клинической цели, а за основу берется регулярный 28-дневный менструальный цикл.'
                }
            },
            {
                path: 'faq',
                name: 'faq',
                component: () =>
                    import ('../views/About/FAQ.vue'),
                meta: {
                    title: 'Medicalka | Часто задаваемые вопросы',
                    description: 'Страница часто задаваемых вопросов (или для краткости страница часто задаваемых вопросов) является ключевой частью базы знаний, поскольку она отвечает на наиболее распространенные вопросы ваших клиентов и полезна на всех этапах пути клиента.'
                }
            },
            {
                path: 'our-process',
                name: 'our-process',
                component: () =>
                    import ('../views/About/Process.vue'),
                meta: {
                    title: 'Medicalka | Наш процесс',
                    description: 'Информацию о здоровье легко найти, но она может быть запутанной, подавляющей, неточной, нерелевантной или недоступной. Мы здесь, чтобы изменить это. Мы предоставляем контент, который заслуживает доверия, точен, основан на фактических данных, ясен, понятен, доступен, сочувствен и практичен'
                }
            },
            {
                path: 'about-us',
                name: 'about-us',
                component: () =>
                    import ('../views/About/Company.vue'),
                meta: {
                    title: 'Medicalka | О нас',
                    description: 'Medicalka.com — это информационный веб-ресурс, посвященный здоровью и благополучию. Сайт предлагает глубоко проработанные статьи и экспертные советы, направленные на улучшение качества жизни и управление здоровьем. Он предоставляет доступ к актуальной и проверенной информации, помогая читателям принимать обоснованные решения о своем здоровье и благополучии. Medicalka.com ориентирован на создание осведомленного и здорового общества, поддерживая личное и семейное здоровье.'
                }
            },
            {
                path: 'woman-calendar',
                name: 'woman-calendar',
                component: () =>
                    import ('../views/Calculators/MenstruationCalc.vue'),
                meta: {
                    title: 'Medicalka | Калькулятор менструаций',
                    description: 'Онлайн расчет дней менструации, овуляции, высокой вероятности зачатия ребенка и даты безопасных дней.'
                }
            },
            {
                path: 'find-doctor',
                name: 'find-doctor',
                component: () =>
                    import ('../views/FindDoctor/index.vue'),
                meta: {
                    title: 'Medicalka | Найти врача',
                    description: 'Задайте вопрос врачу не выходя из дома. Выберите нужную специальность и врач ответит на любой интересующий вас вопрос.'
                }
            },
            {
                path: 'diagnoses',
                name: 'diagnoses',
                component: () =>
                    import ('../views/Diagnosis/index.vue')
            },
            {
                path: 'ask-question',
                name: 'ask-question',
                component: () =>
                    import ('../views/ContactUs/index.vue'),
                meta: {
                    title: 'Medicalka | Задать вопрос',
                    description: 'Не стесняйтесь связываться с нами в любое время. Мы свяжемся с вами, как только сможем'
                }
            },
            {
                path: 'profile',
                name: 'profile',
                component: () =>
                    import ('../views/Profile/index.vue')
            },
            {
                path: 'disclaimer',
                name: 'disclaimer',
                component: () =>
                    import ('../views/Disclaimer/index.vue'),
                meta: {
                    title: 'Medicalka | Дисклеймер',
                    description: 'Дисклеймер'
                }
            },
            {
                path: 'search',
                name: 'search',
                component: () =>
                    import ('../views/Search/index.vue')
            },
            {
                path: 'privacy',
                name: 'privacy',
                component: () =>
                    import ('../views/Privacy/index.vue')
            },
            {
                path: 'ads-politic',
                name: 'ads-politic',
                component: () =>
                    import ('../views/AdsPolicy/index.vue')
            },
            {
                path: 'for-ads',
                name: 'for-ads',
                component: () =>
                    import ('../views/Advertisment/index.vue')
            },
        ],
    }, {
        path: '/:catchAll(.*)',
        name: 'NotFound',
        component: () =>
            import ('../views/404/index.vue')
    },
]
const router = createRouter({
        history: createWebHistory(),
        routes,
        scrollBehavior(to, from, savedPosition) {
            return { top: 0 }
        },
    })
    // router.beforeEach((to, from, next) => {
    //     const lang = to.params.lang
    //     if (!lang) {
    //         return next({ path: `/ru${to.path}` })
    //     }
    //     i18n.global.locale = lang
    //     next()
    // })

router.afterEach((to) => {
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
    const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

    if (nearestWithTitle) {
        document.title = nearestWithTitle.meta.title;
    }

    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

    if (!nearestWithMeta) return;

    nearestWithMeta.meta.metaTags.map(tagDef => {
        const tag = document.createElement('meta');
        Object.keys(tagDef).forEach(key => {
            tag.setAttribute(key, tagDef[key]);
        });
        tag.setAttribute('data-vue-router-controlled', '');
        return tag;
    }).forEach(tag => document.head.appendChild(tag));
});


export default router