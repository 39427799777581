import { faEye, faHeart, faEyeSlash, faComment, faCalendar, faCircleQuestion } from '@fortawesome/free-regular-svg-icons'
import { faEnvelope, faCircleArrowRight, faPlus, faMagnifyingGlass, faCheck, faGlobe, faCircleXmark, faArrowRight, faUser, faCalculator, faTrash, faSpinner, faChevronDown, faChevronUp, faNewspaper, faChartLine, faBullseye, faHandHoldingHeart, faLanguage, faGear, faRightFromBracket, faShare, faCaretDown, faDownload, faCaretUp, faBars, faXmark } from '@fortawesome/free-solid-svg-icons'
import { faTwitter, faVk, faGoogle, faSquareInstagram, faTelegram, faFacebook, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { faThumbsDown as faThumbsDownSolid } from '@fortawesome/free-solid-svg-icons'
import { faThumbsDown as faThumbsDownRegular } from '@fortawesome/free-regular-svg-icons'
import { faThumbsUp as faThumbsUpSolid } from '@fortawesome/free-solid-svg-icons'
import { faThumbsUp as faThumbsUpRegular } from '@fortawesome/free-regular-svg-icons'
export const icons = {
    faEye,
    faEyeSlash,
    faTwitter,
    faVk,
    faXmark,
    faCircleArrowRight,
    faGoogle,
    faThumbsDownSolid,
    faThumbsDownRegular,
    faThumbsUpSolid,
    faThumbsUpRegular,
    faSquareInstagram,
    faTelegram,
    faFacebook,
    faYoutube,
    faHeart,
    faEnvelope,
    faComment,
    faEye,
    faCalendar,
    faCircleQuestion,
    faPlus,
    faMagnifyingGlass,
    faCheck,
    faGlobe,
    faCircleXmark,
    faArrowRight,
    faUser,
    faCalculator,
    faTrash,
    faSpinner,
    faChevronDown,
    faChevronUp,
    faNewspaper,
    faChartLine,
    faBullseye,
    faHandHoldingHeart,
    faLanguage,
    faGear,
    faRightFromBracket,
    faShare,
    faCaretDown,
    faDownload,
    faCaretUp,
    faBars
}