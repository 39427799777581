import axios from 'axios';

const API_BASE_URL = 'https://medicalka.com/'; // Replace with your API base URL

export async function fetchPosts() {
    try {
        const response = await axios.get(`${API_BASE_URL}/posts`);
        return response.data.posts;
    } catch (error) {
        console.error('Error fetching posts:', error);
        return [];
    }
}