<template>
  <div class="navbar">
    <div class="navbar__container">
      <!--  ======  Mobile start  ======= -->
      <div v-if="mobile" class="navbar__container-mobile">
        <font-awesome-icon
          @click="toggleSidebar"
          icon="bars"
          size="2xl"
          style="color: #ffffff"
        />
        <font-awesome-icon
          @click="pushRoute('search')"
          size="lg"
          icon="magnifying-glass"
        />
      </div>
      <!--  ====    Mobile end  ===== -->
      <!-- Logo -->
      <router-link to="/" class="navbar__container-logo"
        ><img :src="require('@/assets/images/logo.png')" :width="200"
      /></router-link>
      <!-- Profile Mobile -->
      <div v-if="mobile" class="navbar__container-user">
        <v-menu v-if="isUser" location="bottom">
          <template v-slot:activator="{ props }">
            <font-awesome-icon
              v-bind="props"
              icon="user"
              class="navbar__container-links-profile-avatar"
            />
          </template>
          <v-list class="navbar__container-links-profile-settings">
            <div @click="pushProfile('profile')">
              <font-awesome-icon
                icon="user"
                class="navbar__container-links-profile-settings-avatar"
              />
              <p>@{{ userInfo?.login }}</p>
            </div>
            <div @click="pushProfile(link.slug)" v-for="link in profileLinks" :key="link">
              <span> {{ link.title }} </span>
            </div>
            <hr />
            <div @click="pushProfile('profile')">
              <font-awesome-icon icon="gear" style="color: #e2e2e2" />
              <span>{{ $t("navbar.profileSettings") }}</span>
            </div>
            <div @click="logOut">
              <font-awesome-icon icon="right-from-bracket" style="color: #e2e2e2" /><span
                >{{ $t("navbar.logOut") }}</span
              >
            </div>
          </v-list>
        </v-menu>
        <span
          v-else-if="!isUser"
          @click="dialogs.dialog = true"
          class="navbar__container-user-registration"
          >{{ $t("navbar.registration") }}</span
        >
      </div>
      <!-- Navigation Links -->
      <div v-if="links" class="navbar__container-links">
        <!-- Topics -->
        <v-menu v-for="link in links" :key="link" open-on-hover>
          <template v-slot:activator="{ props }">
            <button v-bind="props">
              {{ selectedLanguage === "ru" ? link.nameRu : link.nameUz }}
              <font-awesome-icon v-if="props" icon="chevron-down" />
            </button>
          </template>

          <v-list class="navbar__container-links-dropdown">
            <v-list-item
              v-for="topic in link.topics"
              :key="topic"
              @click.stop="topicRoute(topic)"
            >
              <v-list-item-title>
                {{ selectedLanguage === "ru" ? topic.nameRu : topic.nameUz }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <!-- Language and profile -->
        <div class="navbar__container-links-profile">
          <!-- Search  -->
          <div @click="pushRoute('search')" class="navbar__container-links-search">
            <font-awesome-icon size="lg" icon="magnifying-glass" />
          </div>
          <!-- Language -->
          <v-menu location="bottom" transition="slide-x-transition">
            <template v-slot:activator="{ props }">
              <font-awesome-icon
                v-bind="props"
                class="navbar__container-links-profile-global"
                icon="globe"
              />
            </template>
            <v-list class="navbar__container-links-profile-languages">
              <div
                v-for="language in languages"
                :key="language"
                :class="{
                  selectedLanguage: selectedLanguage === language.slug,
                }"
                @click="handleLanguage(language)"
              >
                <span>{{ language.slug }}</span>
                <p>{{ language.title }}</p>
                <font-awesome-icon
                  v-if="selectedLanguage === language.slug"
                  icon="check"
                />
              </div>
            </v-list>
          </v-menu>
          <!-- Profile -->
          <v-menu v-if="isUser" location="bottom">
            <template v-slot:activator="{ props }">
              <v-img
                v-if="userInfo?.imgLink"
                :src="userInfo?.imgLink"
                v-bind="props"
                height="40"
                width="40"
                class="border-3"
              ></v-img>
              <font-awesome-icon
                v-else
                v-bind="props"
                icon="user"
                class="navbar__container-links-profile-avatar"
              />
            </template>
            <v-list class="navbar__container-links-profile-settings">
              <div @click="pushProfile('profile')">
                <v-img
                  v-if="userInfo?.imgLink"
                  :src="userInfo?.imgLink"
                  height="40"
                  width="40"
                  class="border-3"
                ></v-img>
                <font-awesome-icon
                  v-else
                  icon="user"
                  class="navbar__container-links-profile-settings-avatar"
                />
                <p>@{{ userInfo?.login }}</p>
              </div>
              <div
                @click="pushProfile(link.slug)"
                v-for="link in profileLinks"
                :key="link"
              >
                <span> {{ link.title }} </span>
              </div>
              <hr />
              <div @click="pushProfile('profile')">
                <font-awesome-icon icon="gear" style="color: #e2e2e2" />
                <span>{{ $t("navbar.profileSettings") }}</span>
              </div>
              <div @click="logOut">
                <font-awesome-icon
                  icon="right-from-bracket"
                  style="color: #e2e2e2"
                /><span>{{ $t("navbar.logOut") }}</span>
              </div>
            </v-list>
          </v-menu>
          <span v-else @click="dialogs.dialog = true">{{
            $t("navbar.registration")
          }}</span>
        </div>
      </div>
    </div>
  </div>
  <!-- Login Form -->
  <LoginForm :dialogs="dialogs" />
</template>

<script>
import { mapMutations } from "vuex";
import { changeLanguage } from "@/utils/changeLanguage";
export default {
  inject: ["axios"],
  data() {
    return {
      selectedLanguage: localStorage.getItem("language") || "ru",
      languages: [
        {
          title: "Русский",
          slug: "ru",
        },
        {
          title: "O'zbek tili",
          slug: "uz",
        },
      ],
      links: null,
      userInfo: null,
      dialogs: {
        dialog: false,
      },
    };
  },
  computed: {
    profileLinks() {
      return [
        {
          title: this.$t("navbar.publications"),
          slug: "posts",
        },
        {
          title: this.$t("profile.waitingModeration"),
          slug: "moderation",
        },
        {
          title: this.$t("navbar.likedPosts"),
          slug: "favourites",
        },
        {
          title: this.$t("navbar.comments"),
          slug: "comments",
        },
      ];
    },
    isUser() {
      const token = localStorage.getItem("token");
      if (token) {
        return true;
      }
      return false;
    },
  },
  watch: {
    selectedLanguage(newLang, oldLang) {
      localStorage.setItem("language", newLang);
      this.$i18n.locale = newLang;
      this.$router.push(`/${newLang}`);
    },
  },
  methods: {
    ...mapMutations(["setProfileTab", "changeKeyValue"]),
    pushProfile(slug) {
      this.setProfileTab(slug);
      this.$router.push({name: 'profile'});
    },
    handleLanguage(language) {
      this.selectedLanguage = language.slug;
      // window.location.reload();
    },
    logOut() {
      localStorage.removeItem("token");
      this.changeKeyValue();
    },
    getUser(id) {
      this.axios.get(`api/v1/users/${id}`).then((response) => {
        this.userInfo = response.data;
      });
    },
    getMe() {
      this.axios
        .get("auth/v1/me", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((response) => {
          this.getUser(response?.data);
        });
    },
    pushRoute(path) {
      this.$router.push({
        name: path,
      });
    },
    compileUrlText(text) {
      return text.toLowerCase().replace(/\s+/g, "-");
    },
    topicRoute(topic) {
      if (topic.topicType === "RESOURCE") {
        this.$router.push({ name: topic.slug });
      } else {
        const topicTitle = this.compileUrlText(
          this.selectedLanguage === "ru" ? topic.nameRu : topic.nameUz
        );
        localStorage.setItem("topic", topic.id);
        this.$router.push({
          name: "topic-id",
          params: { id: topicTitle },
      });
      }
    },
    getNavLinks() {
      this.axios.get("api/v1/navigations").then((response) => {
        this.links = response.data;
      });
    },
    toggleSidebar() {
      this.$store.commit("toggleSidebar", true);
    },
  },
  mounted() {
    this.getNavLinks();
    if (localStorage.getItem("token")) {
      this.getMe();
    }
  },
  created() {
    this.$i18n.locale = this.selectedLanguage;
  },
};
</script>

<style></style>
