<template>
  <div class="footer">
    <div class="footer__container">
      <div class="footer__container__desc">
        <router-link class="logo" to="/">
          <img :width="200" :src="require('@/assets/images/logo.png')" />
        </router-link>
        <p>{{ $t("home.footerSubTitle") }}</p>
      </div>
      <div class="d-flex" style="gap: 4rem">
        <div class="footer__container-links">
          <div
            @click="topicRoute(link)"
            v-for="link in topics?.slice(0, 3)"
            :key="link"
            class="link"
          >
            {{ selectedLanguage === "ru" ? link.nameRu : link.nameUz }}
          </div>
        </div>
        <div class="footer__container-links">
          <div
            @click="topicRoute(link)"
            v-for="link in topics?.slice(3, 6)"
            :key="link"
            class="link"
          >
            {{ selectedLanguage === "ru" ? link.nameRu : link.nameUz }}
          </div>
        </div>
        <div class="footer__container-links">
          <div
            @click="topicRoute(link)"
            v-for="link in topics?.slice(6, 9)"
            :key="link"
            class="link"
          >
            {{ selectedLanguage === "ru" ? link.nameRu : link.nameUz }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer-copyright">
    <div class="footer-copyright-container">
      <p class="footer-copyright-container-text">© 2024 Medicalka. All rights reserved</p>
      <div class="footer-copyright-container-links">
        <span
          v-for="link in copyrightLinks"
          :key="link.slug"
          @click="pushRoute(link.slug)"
        >
        {{ link.title }}
      </span>
      
      </div>
      <div class="footer-copyright-container-media">
        <a v-for="media in socialMedia" :key="media" :href="media.href">
          <font-awesome-icon :icon="media.icon" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inject: ["axios"],
  data() {
    return {
      selectedLanguage: localStorage.getItem("language") || "ru",
      links: null,
      topics: [],
      copyrightLinks: [
        {
          title: this.$t("footer.disclaimer"),
          slug: "disclaimer",
        },
        {
          title: this.$t("footer.privacy"),
          slug: "privacy",
        },
        {
          title: this.$t("footer.ads_politic"),
          slug: "ads-politic",
        },
        {
          title: this.$t("footer.for_ads"),
          slug: "for-ads",
        },
      ],
      socialMedia: [
        {
          icon: "fa-solid fa-envelope",
          href: "mailto:info.medicalka@gmail.com",
        },
        {
          icon: "fa-brands fa-square-instagram",
          href:
            "https://www.instagram.com/medicalka/?utm_source=ig_web_button_share_sheet&igshid=OGQ5ZDc2ODk2ZA==",
        },
        {
          icon: "fa-brands fa-telegram",
          href: "https://t.me/medicalka_com",
        },
        {
          icon: "fa-brands fa-youtube",
          href: "https://Youtube.com/@Medicalka",
        },
      ],
    };
  },
  computed: {
    isNotHome() {
      return this.$route.path !== "/";
    },
  },
  methods: {
    pushRoute(path) {
      this.$router.push({
        name: path,
      });
    },
    getTopics() {
      this.axios.get("api/v1/topics").then((response) => {
        this.topics = response.data;
      });
    },
    compileUrlText(text) {
      return text.toLowerCase().replace(/\s+/g, "-");
    },
    topicRoute(topic) {
      if (topic.topicType === "RESOURCE") {
        this.$router.push({ name: topic.slug });
      } else {
        const topicTitle = this.compileUrlText(
          this.selectedLanguage === "ru" ? topic.nameRu : topic.nameUz
        );
        localStorage.setItem("topic", topic.id);
        this.$router.push({
          name: "topic-id",
          params: { id: topicTitle },
      });
      }
    },
    getNavLinks() {
      this.axios.get("api/v1/navigations/footer").then((response) => {
        this.links = response.data;
      });
    },
  },
  mounted() {
    this.getTopics();
  },
};
</script>

<style lang="scss"></style>
