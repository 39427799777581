<template>
  <div>
    <v-navigation-drawer class="bg-sidebarColor sidebar" v-model="drawer" permanent app>
      <v-list density="compact" nav>
        <!-- CLOSE -->
        <div class="sidebar__logo">
          <v-img class="sidebar__logo-img" src="@/assets/images/logo-dark.png"></v-img>
          <div class="sidebar__logo-right">
            <!-- Language -->
            <v-menu location="bottom" transition="slide-x-transition">
              <template v-slot:activator="{ props }">
                <font-awesome-icon
                  v-bind="props"
                  class="navbar__container-links-profile-global"
                  icon="globe"
                />
              </template>
              <v-list class="navbar__container-links-profile-languages">
                <div
                  v-for="language in languages"
                  :key="language"
                  :class="{
                    selectedLanguage: selectedLanguage === language.slug,
                  }"
                  @click="handleLanguage(language)"
                >
                  <span>{{ language.slug }}</span>
                  <p>{{ language.title }}</p>
                  <font-awesome-icon
                    v-if="selectedLanguage === language.slug"
                    icon="check"
                  />
                </div>
              </v-list>
            </v-menu>
            <!-- Close -->
            <font-awesome-icon
              @click="closeSidebar"
              class="sidebar__logo-right-close"
              icon="xmark"
            />
          </div>
        </div>
        <!--  ====  LINKS  ==== -->
        <div class="sidebar__links">
          <div class="sidebar__links-link" v-for="link in links" :key="link">
            <v-list-item @click="handleLink(link.id)">
              {{ selectedLanguage === "ru" ? link.nameRu : link.nameUz }}
              <font-awesome-icon
                v-if="selectedLink === link.id"
                :height="12"
                :width="12"
                icon="chevron-up"
              />
              <font-awesome-icon v-else :height="12" :width="12" icon="chevron-down" />
            </v-list-item>
            <div v-if="selectedLink === link.id">
              <div
                v-for="topic in link?.topics"
                :key="topic"
                @click.stop="topicRoute(topic)"
                class="sidebar__links-topics"
              >
                {{ topic.nameRu }}
              </div>
            </div>
          </div>
        </div>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>
<script>
export default {
  inject: ["axios"],
  data() {
    return {
      languages: [
        {
          title: "Русский",
          slug: "ru",
        },
        {
          title: "O'zbek tili",
          slug: "uz",
        },
      ],
      drawer: true,
      links: null,
      selectedLanguage: localStorage.getItem("language") || "ru",
      selectedLink: null,
    };
  },
  watch: {
    isSidebarOpen() {
      this.drawer = this.isSidebarOpen;
    },
    selectedLanguage(newLang, oldLang) {
      localStorage.setItem("language", newLang);
      this.$i18n.locale = newLang;
    },
  },
  computed: {
    isSidebarOpen() {
      return this.$store.getters.getSidebarState;
    },
  },
  mounted() {
    this.drawer = this.isSidebarOpen;
    this.getNavLinks();
  },
  methods: {
    handleLanguage(language) {
      this.selectedLanguage = language.slug;
      window.location.reload();
    },
    compileUrlText(text) {
      return text.toLowerCase().replace(/\s+/g, "-");
    },
    topicRoute(topic) {
      if (topic.topicType === "RESOURCE") {
        this.$router.push({ name: topic.slug });
      } else {
        const topicTitle = this.compileUrlText(
          this.selectedLanguage === "ru" ? topic.nameRu : topic.nameUz
        );
        localStorage.setItem("topic", topic.id);
        this.$router.push({
          name: "topic-id",
          params: { id: topicTitle },
      });
      }
      this.$store.commit("toggleSidebar", false);
      this.selectedLink = null;
    },
    handleLink(id) {
      if (this.selectedLink) {
        this.selectedLink = null;
      } else {
        this.selectedLink = id;
      }
    },
    getNavLinks() {
      this.axios.get("api/v1/navigations").then((response) => {
        this.links = response.data;
      });
    },
    closeSidebar() {
      this.$store.commit("toggleSidebar", false);
    },
  },
};
</script>
